.legal-section-title {
    background-color: #f7f7f7;
    text-align: center;
}

.legal-siteTitle {
    align-items: center;
    background-color: #fff;
    color: #058ce0;
    display: flex;
    font-family: Comfortaa, sans-serif;
    font-size: 22px;
    justify-content: center;
    letter-spacing: .8px;
    margin-bottom: 10px;
    padding-bottom: 100px;
    padding-top: 195px;
    text-decoration: none;
    text-decoration: underline;
    text-shadow: 1px 1px 1px #c9bcbc, 0 0 1em #fff, 0 0 .1em #000;
}

.legal-title {
    margin: 10px 0;
    padding: 0px 5px 0px 10px;
    border-bottom: 2px solid rgb(132, 224, 255);
    font-family: 'Comfortaa', sans-serif;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: .9px;
    text-align: left;
    text-shadow: 1px 1px 1px rgb(245, 228, 228), 0 0 1em white, 0 0 0.1em black;
}

.legal-description {
    padding: 0px 5px 0px 10px;
    margin: 0 5px 25px 0;
    font-family: 'Comfortaa', sans-serif;
    font-size: 14px;
    letter-spacing: .5px;
    line-height: 1rem;
    text-align: left;
    text-shadow: 1px 1px 1px rgb(201, 188, 188), 0 0 1em white, 0 0 0.1em black;
}

.legal-footer {
    padding: 1px 0px 1px 0px;
    display: block;
    color: black;
    font-family: 'Comfortaa', sans-serif;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: .8px;
    line-height: 0.5rem;
    text-shadow: 1px 1px 1px rgb(201, 188, 188), 0 0 1em white, 0 0 0.1em black;
}

@media screen and (max-width: 600px) {
    .legal-section {
        padding: 25px;
    }
    .legal-title {
        font-size: 18px;
    }
    .legeal-description {
        font-size: 12px;
    }
    .legal-footer {
        margin-top: 5px;
        margin-bottom: 0px;
        text-align: center;
        font-size: 15px;
    }
}
