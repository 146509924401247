.footer-section {
    background-image: linear-gradient(132deg, #dfe6e8 0%, #00488a 100%);
}
.footer-container {
    padding: 2px;
    margin-bottom: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex;
    gap: 2px;
    color: white;
    border-bottom: 3px solid #1d80dd;
    text-shadow: 1px 1px 1px white, 0 0 1em rgb(0, 0, 0), 0.1em 0 0.1em black;
    margin-bottom: 110px;
    margin-top: 10px;
}

.ft-title {
    padding-left: 10px;
    color: rgb(255, 255, 255);
    text-decoration: none;
    font-family: 'Madimi One', sans-serif;
    font-size: 25px;
    font-weight: bold;
    letter-spacing: .9px;
    text-shadow: 1px 1px 1px rgb(70, 73, 78), 0 0 0.1em rgb(143, 148, 154), 0.1em 0 0.1em rgb(75, 79, 86);
}

.footer-description {
    padding-left: 10px;
    color: #4ac9f7;
    font-family: 'Rancho', sans-serif;
    font-size: 27px;
    font-weight: bold;
    letter-spacing: .8px;
    line-height: 1.4rem;
    text-shadow: 1px 1px 1px rgb(70, 73, 78), 0 0 0.1em rgb(143, 148, 154), 0.1em 0 0.1em rgb(75, 79, 86);
}

.ft-list-title {
    margin: 3px 8px;
    font-family: 'Madimi One', sans-serif;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: .8px;
    text-shadow: 1px 1px 1px rgb(70, 73, 78), 0 0 0.1em rgb(143, 148, 154), 0.1em 0 0.1em rgb(75, 79, 86);
}

.ft-list-items {
    list-style-type: none;
    margin: 0px 1px;
    padding-right: 5px;

}

.ft-list-items li a {
    text-decoration: none;
    color: #ffffff;
    border-bottom: 1px solid transparent;
    padding: 0 2px 0;
    transition: border .2s ease;
    font-family: 'Madimi One', sans-serif;
    font-size: 15px;
    font-weight: bold;
    text-shadow: 1px 1px 1px rgb(70, 73, 78), 0 0 0.1em rgb(143, 148, 154), 0.1em 0 0.1em rgb(75, 79, 86);

}
.ft-list-items li {
    margin: 1px 0;
    font-family: 'Madimi One', sans-serif;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: .8px;
    text-shadow: 1px 1px 1px rgb(70, 73, 78), 0 0 0.1em rgb(143, 148, 154), 0.1em 0 0.1em rgb(75, 79, 86);

}
.ft-list-items li a:hover {
    border-bottom: 2px solid #ffffff;
}

.ft-copyright {
    padding: 5px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #f6f6f8;
    font-family: 'Madimi One', sans-serif;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: .8px;
    text-shadow: 1px 1px 1px rgb(70, 73, 78), 0 0 0.1em rgb(143, 148, 154), 0.1em 0 0.1em rgb(75, 79, 86);

}

.ft-social-links {
    margin-top: 10px;
    align-items: center;
    list-style-type: none;
    display: flex;
    justify-content: center;
    gap: 12px;
    text-shadow: 1px 1px 1px rgb(70, 73, 78), 0 0 0.1em rgb(143, 148, 154), 0.1em 0 0.1em rgb(75, 79, 86);

}

.ft-social-links li a {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    text-shadow: 1px 1px 1px rgb(70, 73, 78), 0 0 0.1em rgb(143, 148, 154), 0.1em 0 0.1em rgb(75, 79, 86);

}
.ft-social-links li a svg {
    width: 32px;
    height: 32px;
    padding: 1px;
    text-shadow: 1px 1px 1px rgb(70, 73, 78), 0 0 0.1em rgb(143, 148, 154), 0.1em 0 0.1em rgb(75, 79, 86);

}
.ft-social-links li a svg path {
    fill: #fcfcfc;
    text-shadow: 1px 1px 1px rgb(70, 73, 78), 0 0 0.1em rgb(143, 148, 154), 0.1em 0 0.1em rgb(75, 79, 86);

}
.ft-social-links li a:hover, .ft-social-links li a:hover svg path {
    fill: #4ab5f7;
    text-shadow: 1px 1px 1px rgb(70, 73, 78), 0 0 0.1em rgb(143, 148, 154), 0.1em 0 0.1em rgb(75, 79, 86);

}

a {
    color: #4ab5f7;
    text-decoration: underline;
    font-family: 'Madimi One', sans-serif;
    margin-top: -10px;
    letter-spacing: .8px;
    line-height: 1.4rem;
    text-shadow: 1px 1px 1px rgb(70, 73, 78), 0 0 0.1em rgb(143, 148, 154), 0.1em 0 0.1em rgb(75, 79, 86);
}

 a:hover {
    color: #ffffff;
  }

@media screen and (max-width: 700px) {
    .footer-container {
        padding: 10px;
    }
    .ft-description {
        width: 100%;
        margin: 14px 0 22px;
    }
    .ft-input {
        width: 100%;
        margin: 12px 0;
    }
    .ft-copyright {
        padding: 5px;
        display: block;
        text-align: center;
    }
    .ft-social-links {
        margin: 15px 0px 0px 0px;
        padding: 18px;
        text-shadow: 1px 1px 1px rgb(70, 73, 78), 0 0 0.1em rgb(143, 148, 154), 0.1em 0 0.1em rgb(75, 79, 86);

    }
}
