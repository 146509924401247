.particulier-carousel {
  width: 40%;
  height: 50%;
  display: flex;
  border: 3px solid rgb(5, 140, 224);
  border-radius: 22px;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
  margin-bottom: 90px;
}

.particulier-image {
  width: 40%;
  height: 50%;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  margin-top: 90px;
  margin-bottom: 90px;
}

.carousel-caption {
  background: rgba(250, 248, 248, 0.445); 
  padding: 5px; 
  position: absolute;
  text-align: center;
  vertical-align: middle;
  color: #ffffff;
  font-family: 'Comfortaa', sans-serif;
  font-size: 15px;
  font-weight: bold;
  height: 15%;
  letter-spacing: 1.5px;
  font-weight: 800;
  text-shadow: 1px 1px 1px rgb(4, 4, 4), 0 0 1em rgb(189, 171, 171), 0.1 0 0.1em black;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 12px;
  margin-top: 50px;
}

.carousel-control-prev-icon, .carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  color: black;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
  background-color: black;
  border-radius: 10px;
  text-shadow: 1px 1px 1px rgb(251, 248, 248), 0 0 1em white, 0 0 0.1em black;
  box-shadow: 5px 5px 9px 1px rgba(63, 68, 74, 0.4);
}

.carousel-control-prev,
.carousel-control-next {
  font-size: 1.5rem;
  color: #ffffff;
  background: none;
  border: none;
}

.carousel-control-prev:hover,
.carousel-control-next:hover {
  color: #faf7f7;
}

.carousel-indicators .active {
  background-color: black;
  margin-top: 75px;
  display: flex;
  justify-content: center;  
}

.carousel-indicators {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
}

.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 35px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: transparent;
  background-clip: padding-box;
  border: 0;
  border-top: 15px transparent;
  border-bottom: 15px transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}

@media (max-width: 768px) {
  .particulier-carousel {
    width: 100%;
    height: 220px;
  }
}

@media (max-width: 1200px) {
  .particulier-image {
    width: 70%;
    height: 50%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: 120px;
    padding-bottom: 10px;
  }
  }

@media (max-width: 768px) {
.particulier-image {
  width: 70%;
  height: 50%;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  margin-top: 120px;
  padding-bottom: 10px;
}
}

@media (max-width: 576px) {
  .particulier-carousel {
    width: 100%;
    height: 70%;
  }
}

@media (max-width: 576px) {
  .particulier-image {
    width: 90%;
    height: 100%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 100px;
  }
  }

@media (max-width: 576px) {
.carousel-caption {
  background: rgba(255, 255, 255, 0.445);
  padding: 2px;
  position: absolute;
  text-align: center;
  vertical-align: middle;
  font-family: 'Comfortaa', sans-serif;
  font-size: 12px;
  height: 100%;
  width: 100%;
  color: #fff;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 25px;
  margin-bottom: 15px;
}
}

@media (max-width: 768px) {
  .carousel-caption {
  background: rgba(250, 248, 248, 0.445);
  padding: 2px;
  position: absolute;
  text-align: center;
  vertical-align: middle;
  font-family: 'Comfortaa', sans-serif;
  font-size: 15px;
  height: 35%;
  width: 100%;
  color: #fff;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 25px;

}
}

@media (max-width: 1800px) {
  .carousel-caption {
    background: rgba(255, 255, 255, 0.445);
    padding: 2px;
    position: absolute;
    text-align: center;
    vertical-align: middle;
    font-family: 'Comfortaa', sans-serif;
    font-size: 15px;
    height: 20%;
    width: 100%;
    color: #fff;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 25px;
  
  }
  }