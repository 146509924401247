.View-section {
    padding: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1px;
    position: relative;
    overflow: hidden;
}

.video-background {
    object-fit: cover;
    z-index: -1;
    position: absolute;
    width: 100%;
    height: 100%;
    transform-origin: top;
    object-position: 0% 50%;
}

.text-section {
    display: block;
    width: calc(83% - 17%);
    padding: 0px 0px 0px 0px;
}

.text-section > div {
    flex: 1;
}

.text-headline {
    margin-bottom: 12px;
    margin: 55px 0px 0px 0px;
    font-family: 'Madimi One', sans-serif;
    font-size: 40px;
    font-weight: bold;
    letter-spacing: 2.2px;
    font-weight: 800;
    color: rgba(57, 117, 228, 0.85);
    text-shadow: 1px 1px 1px rgb(157, 178, 196), 0 0 1em rgb(238, 238, 245), 0 0 0.1em rgb(65, 61, 61);
    mix-blend-mode: color-burn !important;
}

.text-title {
    width: 700px;
    color: rgb(20, 167, 230);
    font-family: 'Rancho', sans-serif;
    font-size: 39px;
    font-weight: bold;
    font-weight: 800;
    text-shadow: 1px 1px 1px rgb(70, 73, 78), 0 0 0.1em rgb(143, 148, 154), 0.1em 0 0.1em rgb(75, 79, 86);
    }

.text-descritpion {
    width: 575px;
    margin: 22px 0;
    color: rgb(255, 255, 255);
    font-family: 'Rancho', sans-serif;
    font-size: 22px;
    letter-spacing: .7px;
    line-height: 1.5rem;
    text-shadow: 1px 1px 1px rgb(70, 73, 78), 0 0 0.1em rgb(143, 148, 154), 0.1em 0 0.1em rgb(75, 79, 86);
    }

.text-appointment-btn {
    padding: 8px 14px;
    margin-top: 42px;
    margin-bottom: 15px;
    color: rgb(31, 29, 29);
    border-radius: 40px;
    border: 3px solid rgb(0, 0, 0);
    outline: transparent;
    background-color: rgb(255, 255, 255);
    border: px solid rgb(0, 0, 0);
    font-size: 22px;
    font-family: 'Madimi One', sans-serif;
    letter-spacing: .8px;
    cursor: pointer;
    transition: all .4s ease;
    text-shadow: 1px 1px 1px rgb(120, 106, 106), 0 0 1em white, 0 0 0.1em rgb(81, 72, 72);
    box-shadow: 5px 5px 9px 1px rgba(0, 0, 0, 0.4);
}

.text-appointment-btn:hover {
    padding: 8px 15px;
    margin-top: 42px;
    margin-bottom: 15px;
    -webkit-text-fill-color: rgb(0, 255, 47);
    border-radius: 40px;
    outline: transparent;
    background-color: rgb(255, 255, 255);
    border: 3px solid rgb(0, 255, 47);
    font-size: 25px;
    font-family: 'Madimi One', sans-serif;
    letter-spacing: .8px;
    cursor: pointer;
    transition: all .4s ease;
    text-shadow: 1px 1px 1px rgb(4, 4, 4), 0 0 1em rgb(255, 255, 255), 0 0 0.1em rgb(179, 166, 166);
    color: #030303;
  }

.View-image-section {
    width: 70%;
    max-width: 100%;
    text-align: center;
    margin-top: 40px;
}

.View-image1 {
    width: 100%;
    height: auto;
    margin: 15px 0px 15px 30px;
    border-radius: 40px;
    border: 3px solid rgb(5, 140, 224);
    box-shadow: 7px 5px 5px 1px rgba(0, 0, 0, 0.4);
}


.scroll-up {
    width: 45px;
    height: 45px;
    display: none;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: white;
    border: 4px solid white;
    border-radius: 50%;
    background-color: rgb(5, 140, 224);
    position: fixed;
    bottom: 70px;
    right: 50px;
    font-size: 24px;
    z-index: 20;
    cursor: pointer;
    filter: drop-shadow(2px 2px 1px rgba(7,7,7,.882));
}  
.show-scroll {
  display: flex;
}

@media screen and (max-width: 1000px) {
    .View-section {
        padding: 120px 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4px;
        position: relative;
        overflow: hidden;
    }
    .View-image1 {
        display: none;
    }
    .View-image-section {
        width: 0%;
        max-width: 100%;
        text-align: center;
    }
    .text-section {
        width: 100%;
        padding: 0;
    }
    .text-title, .text-descritpion {
        width: 100%;

    }
}
@media screen and (max-width: 600px) {

    .View-section {
        padding: 120px 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4px;
        position: relative;
        overflow: hidden;
    }
    .text-headline {
        font-size: 22px;
    }
    .text-title {
        font-size: 22px;
        font-weight: 800;
        text-shadow: 1px 1px 1px rgb(80, 87, 93), 0 0 1em rgb(131, 131, 146), 0 0 0.1em rgb(24, 23, 23);
    }
    .text-descritpion {
        font-size: 18px;
        text-shadow: 1px 1px 1px rgb(6, 6, 6), 0 0 1em white, 0 0 0.1em black;
    }
    .text-appointment-btn {
        font-size: 22px;
        color: rgb(31, 29, 29);
    }

.scroll-up {
        bottom: 32px;
        right: 32px;
    }

}
