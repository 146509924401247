@import url("https://fonts.googleapis.com/css2?family=Candal&family=Glegoo:wght@400;700&family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Comfortaa&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Candal&family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Madimi+One&family=Rancho&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
html {
    scroll-behavior: smooth;
}

::-webkit-scrollbar {
    width: 10px;
}
::-webkit-scrollbar-track {
    background-color: #ffffff;
}
::-webkit-scrollbar-thumb {
    background-image: linear-gradient(132deg, #dfe6e8 0%, rgb(5, 140, 224) 100%);
    border-radius: 2rem;
}