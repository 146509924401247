.section {
  padding: 100px 0px 35px 0px;
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -5px;
    margin-right: -5px;
    margin-bottom: 5px;
}

h5, .h5 {
  margin-top: 22px;
  font-family: "Madimi One", sans-serif;
  font-weight: 700;
  color: rgb(5, 140, 224);
  text-shadow: 1px 1px 1px rgb(44, 40, 40), 0 0 1em rgb(249, 239, 239), 0 0 0.1em rgb(31, 26, 26);
  font-size: 35px;
  margin-left: -48px;
}

h6, .h6 {
  margin-top: 22px;
  font-family: "Madimi One", sans-serif;
  font-weight: 700;
  color: rgb(5, 140, 224);
  text-shadow: 1px 1px 1px rgb(44, 40, 40), 0 0 1em rgb(249, 239, 239), 0 0 0.1em rgb(31, 26, 26);
  font-size: 35px;
  margin-left: 55px;
}

.form-control {
    border-radius: 0 15px 15px;
    box-shadow: 5px 5px 9px 1px rgba(6, 6, 6, 0.4);
    text-shadow: 1px 1px 1px rgb(215, 206, 206), 0 0 1em rgb(255, 255, 255), 0 0 0.1em rgb(6, 6, 6);
    margin-bottom: 5px;
    background: aliceblue;
    border: 4px solid rgb(5, 140, 224);
    color: rgb(5, 140, 224);
    font-family: 'Nunito';
    font-size: medium;
    font-weight: 800;
    display: block;
    width: 100%;
    margin: 4px;
    height: calc(1.2rem + 0.7rem + 0.7rem);
    padding: 0.9rem 0.9rem 0.9rem 0.9rem;
    line-height: 1.4rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    margin-left: -30px;
}

textarea.form-control {
  height: auto;
}


.btn-main {
    padding: 12px 18px;
    color: rgb(249, 248, 248);
    border: 3px solid white;
    border-radius: 28px;
    outline: transparent;
    background-color: rgb(5, 140, 224);
    font-size: 18px;
    font-weight: 800;
    font-family: 'Madimi One', sans-serif;
    letter-spacing: .8px;
    cursor: pointer;
    transition: all .4s ease;
    text-shadow: 1px 1px 1px rgb(11, 11, 11), 0 0 1em white, 0 0 0.1em rgb(18, 17, 17);
    box-shadow: 5px 5px 9px 1px rgba(1, 9, 20, .4);
}

.btn-main:hover {
    padding: 12px 18px;
    background: #f7f7f7;
    color: #058ce0;
    border: 3px solid #058ce0;
    text-shadow: 1px 1px 1px rgb(167, 159, 159), 0 0 1em rgb(252, 252, 252), 0 0 0.1em rgb(192, 182, 182);
    color: #058ce0;
    transition: all .4s ease;
}

  #map-tile {
    margin-top: 30px;
    margin-bottom: 25px;
    height: 280px;
    width: 95%;
    position:relative;
    display:flex;
    border: 4px solid rgb(5, 140, 224);
    box-shadow: 5px 5px 9px 1px rgba(1, 9, 20, .4);
    overflow: hidden;
    border-radius: 15px 15px 0px 0px;
    margin-left: 50px;
  }
    
  #map-tile:hover {
    box-shadow: 4px 2px 2px 2px #7c8486;
  }
  
  #map-tile span {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    text-align: center;
    font-size: 1.6em;
    text-transform: uppercase;
    background-color: #000;
    color: rgb(5, 140, 224);
    opacity: .8;
    font-family: 'Nunito';
    text-shadow: 1px 1px 1px rgb(226, 229, 226), 0 0 1em rgb(249, 239, 239), 0 0 0.1em rgb(246, 249, 245);
    padding-top: 5px;
    padding-bottom: 1px;
  }

  .section-adress {
  font-family: "Madimi One", sans-serif;
  font-weight: 700;
  color: rgb(5, 140, 224);
  text-shadow: 1px 1px 1px rgb(0, 0, 0), 0 0 1em rgb(255, 255, 255), 0 0 0.1em rgb(0, 0, 0);
  font-size: 25px;
  margin-left: 70px;

  }

  .section-adress:hover {
    color: #ffffff;
    text-shadow: 1px 1px 1px rgb(11, 11, 11), 0 0 1em rgb(252, 252, 252), 0 0 0.1em rgb(14, 14, 14);
  }

  @media screen and (max-width: 992px) {

    h5, .h5 {
      margin-top: 22px;
      font-family: "Madimi One", sans-serif;
      font-weight: 700;
      color: rgb(5, 140, 224);
      text-shadow: 1px 1px 1px rgb(44, 40, 40), 0 0 1em rgb(249, 239, 239), 0 0 0.1em rgb(31, 26, 26);
      font-size: 35px;
      margin-left: 0px;
    }
    
    h6, .h6 {
      margin-top: 22px;
      font-family: "Madimi One", sans-serif;
      font-weight: 700;
      color: rgb(5, 140, 224);
      text-shadow: 1px 1px 1px rgb(44, 40, 40), 0 0 1em rgb(249, 239, 239), 0 0 0.1em rgb(31, 26, 26);
      font-size: 35px;
      margin-left: 0px;
    }

    .form-control {
      border-radius: 0 15px 15px;
      box-shadow: 5px 5px 9px 1px rgba(6, 6, 6, 0.4);
      text-shadow: 1px 1px 1px rgb(215, 206, 206), 0 0 1em rgb(255, 255, 255), 0 0 0.1em rgb(6, 6, 6);
      margin-bottom: 5px;
      background: aliceblue;
      border: 4px solid rgb(5, 140, 224);
      color: rgb(5, 140, 224);
      font-family: 'Nunito';
      font-size: medium;
      font-weight: 800;
      display: block;
      width: 100%;
      height: calc(1.5rem + 0.7rem + 0.7rem);
      padding: 0.9rem 0.9rem 0.9rem 0.9rem;
      margin: 5px;
      line-height: 1.5rem;
      transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  }
  
  .btn-main {
      padding: 12px 18px;
      color: rgb(249, 248, 248);
      border: 3px solid white;
      border-radius: 28px;
      outline: transparent;
      background-color: rgb(5, 140, 224);
      font-size: 18px;
      font-weight: 800;
      font-family: 'Madimi One', sans-serif;
      letter-spacing: .8px;
      cursor: pointer;
      transition: all .4s ease;
      text-shadow: 1px 1px 1px rgb(11, 11, 11), 0 0 1em white, 0 0 0.1em rgb(18, 17, 17);
      box-shadow: 5px 5px 9px 1px rgba(1, 9, 20, .4);
  }
  
  .btn-main:hover {
    padding: 12px 18px;
    background: #f7f7f7;
      border: 3px solid #058ce0;
      text-shadow: 1px 1px 1px rgb(167, 159, 159), 0 0 1em rgb(252, 252, 252), 0 0 0.1em rgb(192, 182, 182);
      color: #030303;
      transition: all .4s ease;
  }
  
    #map-tile {
      margin-top: 30px;
      margin-bottom: 25px;
      height: 280px;
      width: 95%;
      position:relative;
      display:flex;
      margin-left: auto;
      margin-right: auto;
      border: 4px solid rgb(5, 140, 224);
      box-shadow: 5px 5px 9px 1px rgba(1, 9, 20, .4);
      overflow: hidden;
      border-radius: 15px 15px 0px 0px;
    }
      
    #map-tile:hover {
      box-shadow: 4px 2px 2px 2px #7c8486;
    }
    
    #map-tile span {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 100%;
      text-align: center;
      font-size: 1.6em;
      text-transform: uppercase;
      background-color: #000;
      color: rgb(5, 140, 224);
      opacity: .8;
      font-family: 'Nunito';
      text-shadow: 1px 1px 1px rgb(226, 229, 226), 0 0 1em rgb(249, 239, 239), 0 0 0.1em rgb(246, 249, 245);
      padding-top: 5px;
      padding-bottom: 1px;
    }
  
    .section-adress {
    font-family: "Madimi One", sans-serif;
    font-weight: 700;
    color: rgb(5, 140, 224);
    text-shadow: 1px 1px 1px rgb(0, 0, 0), 0 0 1em rgb(255, 255, 255), 0 0 0.1em rgb(0, 0, 0);
    font-size: 25px;
    margin-left: 5px;
  
    }
  
    .section-adress:hover {
      color: #ffffff;
      text-shadow: 1px 1px 1px rgb(11, 11, 11), 0 0 1em rgb(252, 252, 252), 0 0 0.1em rgb(14, 14, 14);
    }
  }

  hr {
    position: absolute;
    display: block;
    align-items: center;
    margin-block-start: 129px;
    margin-left: -6px;
    height: 8px;
    width: 100%;
    max-width: 1029px;
    border: 2px solid rgb(0, 128, 255);
    background-color: rgb(0, 128, 255);
    box-shadow: 0px 0px 12px 2px rgb(0, 128, 255);
    border-radius: 35px;
      -webkit-transform: translate(-50%, -50%) rotate(90deg) scale(0.8);
      -ms-transform: translate(-50%, -50%) rotate(90deg) scale(0.8);
      transform: translate(-50%, -50%) rotate(90deg) scale(0.8);
    }

@media screen and (max-width: 992px) {
  hr {
    display: none;
  }
}
  
  @media screen and (max-width: 991px) {
    hr {
    margin-top: 5px;
    position: absolute;
    display: flex;
    align-items: center;
    height: 8px;
    width: 88%;
    max-width: 700px;
    -webkit-transform: translate(50%, 50%) rotate(90deg) scale(0.8);
    -ms-transform: translate(50%, 50%) rotate(90deg) scale(0.8);
    transform: translate(50%, 50%) rotate(90deg) scale(0.8);
    transform: none;
    }
    .sepText {
      top: 0%;
    }
  }

  @media screen and (max-width: 768px) {
    hr {
    margin-top: 5px;
    position: absolute;
    display: flex;
    align-items: center;
    height: 8px;
    width: 95%;
    max-width: 530px;
    -webkit-transform: translate(50%, 50%) rotate(90deg) scale(0.8);
    -ms-transform: translate(50%, 50%) rotate(90deg) scale(0.8);
    transform: translate(50%, 50%) rotate(90deg) scale(0.8);
      transform: none;
    }
    .sepText {
      top: 0%;
    }
  }

  @media screen and (max-width: 500px) {
    hr {
    margin-top: 5px;
    position: absolute;
    display: flex;
    align-items: center;
    height: 8px;
    width: 92%;
    -webkit-transform: translate(50%, 50%) rotate(90deg) scale(0.8);
    -ms-transform: translate(50%, 50%) rotate(90deg) scale(0.8);
    transform: translate(50%, 50%) rotate(90deg) scale(0.8);
      transform: none;
    }
    .sepText {
      top: 0%;
    }
  }

.alert {
    --bs-alert-bg: #0000;
    --bs-alert-padding-x: 0.1rem;
    --bs-alert-padding-y: 0rem;
    --bs-alert-margin-bottom: 0px;
    --bs-alert-color: inherit;
    --bs-alert-border-color: #0000;
    --bs-alert-border: var(--bs-border-width) solid var(--bs-alert-border-color);
    --bs-alert-border-radius: var(--bs-border-radius);
    --bs-alert-link-color: inherit;
    background-color: var(--bs-alert-bg);
    border: var(--bs-alert-border);
    border-radius: var(--bs-alert-border-radius);
    color: var(--bs-alert-color);
    margin-bottom: var(--bs-alert-margin-bottom);
    padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
    position: relative;
    margin-left: 30px;
    margin-right: 70px;
    margin-top: -26px;
    text-align: center;
    --bs-alert-color: var(--bs-success-text-emphasis);
    --bs-alert-bg: var(--bs-success-bg-subtle);
    --bs-alert-border-color: var(--bs-success-border-subtle);
    --bs-alert-link-color: var(--bs-success-text-emphasis);
}

@media screen and (max-width: 992px) {

  .alert {
    --bs-alert-bg: #0000;
    --bs-alert-padding-x: 0.1rem;
    --bs-alert-padding-y: 0.1rem;
    --bs-alert-margin-bottom: 1px;
    --bs-alert-color: inherit;
    --bs-alert-border-color: #0000;
    --bs-alert-border: var(--bs-border-width) solid var(--bs-alert-border-color);
    --bs-alert-border-radius: var(--bs-border-radius);
    --bs-alert-link-color: inherit;
    background-color: var(--bs-alert-bg);
    border: var(--bs-alert-border);
    border-radius: var(--bs-alert-border-radius);
    color: var(--bs-alert-color);
    margin-bottom: var(--bs-alert-margin-bottom);
    padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
    position: relative;
    text-align: center;
    --bs-alert-color: var(--bs-success-text-emphasis);
    --bs-alert-bg: var(--bs-success-bg-subtle);
    --bs-alert-border-color: var(--bs-success-border-subtle);
    --bs-alert-link-color: var(--bs-success-text-emphasis);
    margin-left: 10px;
    margin-right: 10px;
}
  }
  
